<template>
  <div class="view">
    <div class="header"></div>
    <div class="main">
      <img class="title" src="@/assets/life-service/living-payment_title.png" alt="" />

      <div class="list_1">
        <div class="item" @click="itemClick()">
          <img class="icon" src="@/assets/life-service/dianfei.png" alt="" />
          <div class="name">电费</div>
          <div class="btn">立即缴费</div>
        </div>
        <div class="item" @click="itemClick()">
          <img class="icon" src="@/assets/life-service/shuifei.png" alt="" />
          <div class="name">水费</div>
          <div class="btn">立即缴费</div>
        </div>
        <div class="item" @click="itemClick()">
          <img class="icon" src="@/assets/life-service/ranqifei.png" alt="" />
          <div class="name">燃气费</div>
          <div class="btn">立即缴费</div>
        </div>
      </div>

      <div class="list_2">
        <div class="item" @click="itemClick()">
          <img class="icon" src="@/assets/life-service/wuyefei.png" alt="" />
          <div class="name">物业费</div>
        </div>
        <div class="item" @click="itemClick()">
          <img class="icon" src="@/assets/life-service/kuandai.png" alt="" />
          <div class="name">宽带</div>
        </div>
        <div class="item" @click="itemClick()">
          <img class="icon" src="@/assets/life-service/youxiandianshi.png" alt="" />
          <div class="name">有线电视</div>
        </div>
        <div class="item" @click="itemClick()">
          <img class="icon" src="@/assets/life-service/guhua.png" alt="" />
          <div class="name">固话</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {}
  },

  filters: {},
  computed: {},
  watch: {},

  created() {},

  methods: {
    itemClick() {
      this.$toast('即将开通')
    }
  }
}
</script>

<style lang="scss" scoped>
.view {
  min-height: 100vh;
  background-color: #f6f6f8;
  .header {
    height: 812px;
    background: url('~@/assets/life-service/living-payment_header_bg.png') no-repeat 0 0 / 100% auto;
  }

  .main {
    width: 710px;
    margin: -520px auto 0;
    padding: 48px 0 60px;
    background-color: #ffffff;
    border-radius: 16px;

    .title {
      display: block;
      width: 428px;
      margin: 0 auto;
    }

    .list_1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 60px;
      padding: 0 50px;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 180px;
        padding: 30px 20px 20px;
        background: #f5faff;
        border-radius: 12px;

        .icon {
          width: 80px;
        }

        .name {
          margin-top: 12px;
          font-size: 28px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }

        .btn {
          margin-top: 28px;
          width: 140px;
          height: 46px;
          background: #3a89ff;
          border-radius: 23px;
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 46px;
          text-align: center;
        }
      }
    }

    .list_2 {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 54px;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
          width: 66px;
        }

        .name {
          margin-top: 20px;
          font-size: 28px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
      }
    }
  }
}
</style>
